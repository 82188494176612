"use client";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import {
  Arrow,
  CardContent,
  CardDesc,
  CardImg,
  CardTitle,
  CommonCard,
  TextButton,
} from "./molecules";
import dayjs from "dayjs";
import { getImageUrlNew, getLink } from "../lib/util";
import Image from "next/image";
import { useDictionary } from "../context/dictionaryProvider";
import Link from "next/link";
import { useParams } from "next/navigation";
import { Autoplay, Navigation } from "swiper/modules";

export default function BlogCarousel({ data = [] }) {
  const params = useParams();
  const dict = useDictionary();
  const WrapImgLeft = ({ className }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="71"
        height="72"
        viewBox="0 0 71 72"
        fill="none"
        className="z-10 absolute -right-[1px] top-0 bottom-auto"
      >
        <path
          d="M13.7419 10.548C13.4802 1.96949 4.47158 -0.725047 0 -1H71V72C71 68.654 70.104 65.9521 68.8363 63.8358C65.7667 58.7115 59.1067 57.9774 53.1334 57.9774H37.7419C24.4871 57.9774 13.7419 47.2322 13.7419 33.9774V10.548Z"
          fill="#fff"
        />
      </svg>
    );
  };
  return (
    <Swiper
      loop
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      speed={1000}
      pagination={{
        clickable: true,
      }}
      spaceBetween={30}
      // slidesPerView={data?.length > 3 ? 3.8 : 1}
      navigation={true}
      modules={[Navigation, Autoplay]}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      style={{ position: "unset" }}
      className="w-full blogCarousel"
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2.8,
          spaceBetween: 30,
        },
        1280: {
          slidesPerView: 3.8,
          spaceBetween: 30,
        },
      }}
    >
      {data?.map((item, index) => (
        <SwiperSlide key={index}>
          <CommonCard>
            <Link
              className="relative block lg:hidden"
              href={getLink(`/${params?.lang}/blog/${item?.slug}`)}
            >
              <WrapImgLeft />
              <Arrow classNames="left-auto top-0" />
            </Link>
            {item?.thumbnail_image?.url && (
              <div className="h-[302px] md:h-[380px] w-full rounded-2xl relative overflow-hidden">
                <Image
                  loading="lazy"
                  src={getImageUrlNew(item?.thumbnail_image, "medium")}
                  alt="No Image"
                  objectFit="cover"
                  className="rounded-2xl w-full h-full"
                  fill
                />
              </div>
            )}

            <CardContent className="text-left">
              <p className="text-sm font-normal text-[#1a1414] mb-2">
                {item?.created_date
                  ? dayjs(item?.created_date).format("DD MMMM YYYY")
                  : dayjs(item?.publishedAt).format("DD MMMM YYYY")}
              </p>
              <Link
                prefetch={false}
                href={getLink(`/${params?.lang}/blog/${item?.slug}`)}
              >
                <CardTitle>{item?.title}</CardTitle>
              </Link>
              <CardDesc>{item?.short_description}</CardDesc>
            </CardContent>
          </CommonCard>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
